<template>
  <b-sidebar-page :mobileMaxWidth="992" class="h-100">
    <template v-slot:sidebar="{ isMobile }">
      <div class="sidebar">
        <div class="sidebar-content text-white p-3">
          <div v-if="isMobile" class="mt-3"></div>
          <router-link v-if="!isMobile" to="/login">
            <img src="/img/logo_bike_branco.svg" width="80%" />
          </router-link>
          <div class="mt-1 font-icon" v-if="!isMobile">
            <i class="far fa-copyright"></i>
            <small> Versão: {{ version }}</small>
          </div>
          <hr v-if="!isMobile" />

          <div v-b-toggle.sidebar-group-usuario class="nav-link pointer mt-1">
            <span>{{ nomeUsuario }}</span>
            <small class="collapse-flip-icon ml-2">
              <i class="fas fa-caret-down"></i>
            </small>
          </div>
          <b-collapse id="sidebar-group-usuario">
            <b-nav vertical class="text-left">
              <b-nav-item @click="onClickLogout">
                <i class="fas fa-sign-out-alt mr-3"></i>
                <span>Sair</span>
              </b-nav-item>
            </b-nav>
          </b-collapse>

          <hr />
          <b-nav vertical class="text-left">
            <b-nav-item to="/cotacao" replace>
              <i class="fas fa-plus-square mr-4"></i>
              <span>Contratar seguro</span>
            </b-nav-item>
            <b-nav-item to="/cliente">
              <i class="fas fa-list mr-4"></i>
              <span>Minhas apólices</span>
            </b-nav-item>
            <b-nav-item to="/cliente/minha-indicacoes">
              <i class="fas fa-wallet mr-4"></i>
              <span>Saldo indicação</span>
            </b-nav-item>
            <b-nav-item to="/cliente/editar-conta">
              <i class="fas fa-user-shield mr-4"></i>
              <span>Meus dados</span>
            </b-nav-item>
            <b-nav-item
              target="_blank"
              :href="
                `https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=Olá, meu nome é ${nomeUsuario} e preciso de atendimento.`
              "
            >
              <i class="fab fa-whatsapp mr-4"></i>
              <span>Fale com a gente</span>
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </template>
    <template v-slot:content="{ toggleSideBar, isMobile }">
      <div class="h-100">
        <div>
          <b-navbar type="dark" class="px-3" v-if="isMobile" toggleable="lg">
            <div class="row no-gutters w-100 align-items-center">
              <button @click="toggleSideBar" class="navbar-toggler p-1">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="col text-center">
                <router-link to="/">
                  <img src="/img/logo_bike.svg" width="180" />
                </router-link>
                <i class="far fa-copyright font-icon text-white ml-2"></i>
                <span class="text-white font-icon"> Versão: {{ version }}</span>
              </div>

              <div style="width:40px;">&nbsp;</div>
            </div>
          </b-navbar>
          <div class="top-line"></div>
        </div>

        <div class="dasboard-view-container">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </template>
  </b-sidebar-page>
</template>

<script>
import BSidebarPage from "./BSidebarPage";
import auth from "../auth";
import config from "../config";
import { version } from "../../package";

export default {
  components: {
    BSidebarPage,
  },
  data() {
    return {
      version,
      config,
      auth,
      nomeUsuario: undefined,
      fotoUsuario: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    var userInfo = auth.getUserInfo();
    if (userInfo.id) {
      next();
    } else {
      next("/login");
    }
  },
  mounted() {
    this.nomeUsuario = auth.getUserName();
  },
  methods: {
    onClickLogout() {
      auth.logout();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";
.navbar-toggler-icon {
  background-image: url("/img/menu.svg")!important;
}
.font-icon {
  font-size: 11px;
}
.sidebar {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
  height: 100vh;
  position: relative;
}
.sidebar-content {
  position: absolute;
  text-align: center;
}

.sidebar-content .nav-link,
.sidebar-content .nav-link:visited {
  color: white;
  margin-bottom: 16px;
}

.sidebar-content .fas {
  font-size: 1.2em;
}

.sidebar-content .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: @border-radius-small;
}

.sidebar hr {
  // width: 90%;
  border-top: 1px solid rgba(180, 180, 180, 0.3);
}

nav {
  background-color: @dark-primary-color;
}

.top-line {
  width: 100%;
  height: 4px;
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}

.dasboard-view-container {
  min-height: calc(100% - 62px);
}
</style>
