<template>
  <div>
    <b-sidebar
      :backdrop="isMobile"
      no-header
      shadow
      v-model="sidebarVisible"
      :width="sideBarWidth + 'px'"
      :no-close-on-route-change="!isMobile"
    >
      <slot
        name="sidebar"
        :toggleSideBar="onClickToggleSideBar"
        :isMobile="isMobile"
      ></slot>
    </b-sidebar>
    <!-- <div></div> -->
    <div
      class="content h-100"
      :style="
        !isMobile && sidebarVisible
          ? 'padding-left: ' + sideBarWidth + 'px;'
          : ''
      "
    >
      <slot
        name="content"
        :toggleSideBar="onClickToggleSideBar"
        :isMobile="isMobile"
      ></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sideBarWidth: {
      type: Number,
      default: 260,
    },
    mobileMaxWidth: {
      type: Number,
      default: 768,
    },
  },
  data() {
    return {
      sidebarVisible: true,
      isMobile: false,
    };
  },
  created() {
    this.checkIsMobile();

    window.addEventListener("resize", this.checkIsMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkIsMobile);
  },

  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth <= this.mobileMaxWidth;

      if (this.isMobile) {
        this.sidebarVisible = false;
      } else {
        this.sidebarVisible = true;
      }
    },
    onClickToggleSideBar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
  },
};
</script>

<style scoped>
.content {
  transition: 400ms;
}
</style>
